<template>
	<div>
		<div class="mh100 bg-white boderra8 box-shadow0010 relative">
			<div class="h54 align-items-center">
				<div class="title-item center fz16 cure-pointer h100" :class="titleIndex == i ? 'co-010085' : 'co-333333'" v-for="(item, i) in titleList" :key="i" @click="titleChoose(i)">{{item}}</div>
			</div>
			
			<div class="paddlr30 flex-wrap">
				<div class="coupon-item marr30 bg-FAFAFA marb30" v-for="(item, i) in list" :key="i">
					<div class="coupon-item-top relative">
						<img class="coupon-item-top display-block" v-if="item.status == 3 || item.status == 2" src="@/assets/img/no_use.png" alt="" />
						<img class="coupon-item-top display-block" v-else src="@/assets/img/is_use.png" alt="" />
						<div class="absolute left0 top0 right0 bottom0 center flex-column">
							<div class="align-items-end">
								<span class="fz20 font-blod" :class="item.status == 3|| item.status == 2 ? 'co-666666' : 'co-010085'">￥</span>
								<span class="fz40 l36 font-blod" :class="item.status == 3|| item.status == 2 ? 'co-666666' : 'co-010085'">{{item.price}}</span>
							</div>
							<div class="fz14 co-666666 mart20" :class="item.status ==3 || item.status == 2 ? 'co-666666' : 'co-333333'">{{item.name}}</div>
						</div>
					</div>
					<div class="mart20 flex-column align-items-center">
						<div class="center w100 " style="font-size: 13px;">有效期：{{item.start_time}}-{{item.end_time}}</div>
						<div class="paddb14 mart55">
							<div class="btn-03 center fz14 co-333333" v-if="item.status == 3">待使用</div>
							<div class="btn-03 center fz14 co-333333" v-if="item.status == 2">已使用</div>
							<div class="btn-03 btn-04 center fz14 co-333333 cure-pointer" v-if="item.status == 1">待使用</div>
						</div>
					</div>
				</div>
				<loadMore :state="state" loading_icon></loadMore>
			</div>
		
		</div>
		<div class="center paddt54 ">
			<el-pagination background  class="center "  @current-change="handleCurrentChange"
			    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
			</el-pagination>
		</div>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				titleList: ['全部', '待使用', '已使用', '已过期'], // 收藏导航
				titleIndex: 0, // 导航选中
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				maxnum:'' ,//总条数
					size: 10,
			}
		},
		mounted() {
			
			this.getlist();
		},
		methods: {
			getlist(){	
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 10;
				this.$req({
					method: 'get',
					data: {
						status:this.titleIndex,
						page: page,
						size: size
					},
					url: '/api/rockys/coupon',
					success: res => {
						console.log(res,'优惠券')
						let list = res;
						this.list = list;
						if (list.length == size) {
							this.state = 1;
						} else if (list.length > 0) {
							this.state = 2;
						} else if (this.list.length == 0) {
							this.state = 3;
						}
						this.loading = false;
					},
					fail: error => {
						if (this.list.length == 0) {
							this.state = 3;
						} else {
							this.state = 2;
						}
						this.loading = false;
					}
				});
				//总条数
				this.$req({
					method: 'get',
					data: {
						status:this.titleIndex,
						show_page:1,
						
					},
					url: '/api/rockys/coupon',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			},
			// 导航选中
			titleChoose(e) {
				if (this.titleIndex == e) return
				this.titleIndex = e
				this.list = [];
				this.page = 0;
				this.state = 0;
				this.loading = false;
				this.getlist();
			},
			handleCurrentChange(val) {
			   this.page = val;
			   this.list = [];
			   this.loading = false;
			   this.getlist()
			    document.documentElement.scrollTop = document.body.scrollTop = 400;
			},
		}
	}
</script>

<style lang="less">
	.title-item {
		padding: 0 30px;
	}
	.pob{
		position: absolute;
		bottom :0;
		width: 100%;
		height: 120px;
	}
	.coupon-item {
		width: 244px;
		
		.coupon-item-top {
			width: 244px;
			height: 165px;
		}
		
		.btn-03 {
			width: 120px;
			height: 32px;
			border: 1px solid #999999;
			border-radius: 17px;
		}
		
		.btn-04:hover {
			background: #010085;
			border: 1px solid #010085;
			color: #FFFFFF;
		}
	}
	
	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999!important;
	}
	
	.btn-prev, .btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}
	
	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
